import React from 'react';

function Loading({ state }) {
  return (
    <div className="loading">
      <div className="spinner-border text-dark"></div>
    </div>
  );
}

export default Loading;
