import React from 'react';
import { Col } from 'react-bootstrap';
import Cash from './Cash';
import Span from './Span';
import Split from './Split';

const Pay = ({ payment, getPaymentDetails, total }) => {
  return (
    <Col md={{ span: 12, offset: 0 }}>
      <Col>
        <h4 className="heading-line">
          Payment by <span className="text-capitalize">{payment}</span>
        </h4>
      </Col>
      {payment === 'span' ? (
        <Span
          payment={payment}
          parentCallback={getPaymentDetails}
          total={total}
        />
      ) : payment === 'cash' ? (
        <Cash
          payment={payment}
          parentCallback={getPaymentDetails}
          total={total}
        />
      ) : payment === 'split' ? (
        <Split
          payment={payment}
          parentCallback={getPaymentDetails}
          total={total}
        />
      ) : null}
    </Col>
  );
};

export default Pay;
