import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Col,
  Row,
  Form,
  InputGroup,
  Button,
  Table,
  Pagination,
} from 'react-bootstrap';
import Loading from '../Loading/Loading';
import useAuth from '../../hooks/useAuth';
import { deviceId } from '../../context/firestore';
import axios from 'axios';
import HistoryGrid from './HistoryGrid';

export default function HistorySpa() {
  const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;
  const { auth } = useAuth();
  const navigate = useNavigate();
  const token = auth.access_token;
  let date = moment(new Date()).format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [historyOrders, setHistoryOrders] = useState([]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(50);
  const [totalPages, setTotalPages] = useState(1);

  const getHistory = async () => {
    if (token) {
      setLoading(true);
      const URL = SPA_SERVER_URL + 'api/spa/GetSpaOrderHistoryByDate';
      const data = {
        take,
        skip,
        startDate,
        endDate,
      };
      await axios
        .post(URL, new URLSearchParams(data), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            deviceid: deviceId,
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          setLoading(false);
          //console.log(response.data);
          setHistoryOrders(response.data);
          setTotalPages(Math.ceil(response.data.totalOrders / take));
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.status === 401) {
              toast.error('Not LoggedIn !');
              navigate('/login');
            }
            toast.error(error.response);
          } else if (error.request) {
            toast.error(error.request);
          } else {
            toast.error(error);
          }
        });
    } else {
      setLoading(false);
      toast.error('Your Session is Expired');
      navigate('/login');
    }
  };

  const onPageChange = (currentPage) => {
    console.log(currentPage);
  };
  // pagination
  const currentPage = 1;
  const createPaginationItem = (i) => {
    return (
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  };
  const paginationItems = [];

  // Create page numbers in the middle (10-14)
  const midpoint = Math.ceil(totalPages / 2);
  if (midpoint >= 4) {
    // Add the first item (page 1)
    paginationItems.push(createPaginationItem(1));
    // Add the first ellipsis
    paginationItems.push(<Pagination.Ellipsis />);
    for (let i = midpoint; i <= midpoint + 8; i++) {
      paginationItems.push(createPaginationItem(i));
    }
    // Add the second ellipsis
    paginationItems.push(<Pagination.Ellipsis />);
  }
  // Add the last item (page 20)
  paginationItems.push(createPaginationItem(totalPages));
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className="mt-3">
            <Col>
              <h6 className="heading heading-col">Orders History</h6>
            </Col>
            <Col className="mt-5" sm={12}>
              <Col sm={{ span: 8, offset: 2 }}>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Start Date"
                    type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    max={date}
                    name="startdate"
                    value={startDate}
                  />
                  <InputGroup.Text>To</InputGroup.Text>
                  <Form.Control
                    aria-label="End Date"
                    type="date"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    max={date}
                    name="enddate"
                    value={endDate}
                  />
                  <Button
                    variant="outline-dark"
                    id="getHistory"
                    onClick={getHistory}
                  >
                    Search History
                  </Button>
                </InputGroup>
              </Col>
            </Col>
          </Row>
          <Row style={{ height: 'auto' }} className="column mt-2">
            {historyOrders.length === 0 ? (
              <>
                <Col>
                  <Alert>No Orders History Found!</Alert>
                </Col>
              </>
            ) : historyOrders.SpaorderHistory.length === 0 ? (
              <>
                <Col>
                  <Alert>No Orders History Found Against This Date!</Alert>
                </Col>
              </>
            ) : (
              <>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Total Orders</th>
                      <th>Cancel Orders</th>
                      <th>
                        Order by <br></br>Span
                      </th>
                      <th>
                        Order By <br></br>Credit Card
                      </th>
                      <th>
                        Order By <br></br>Crystal
                      </th>
                      <th>
                        Order By<br></br>Cash
                      </th>
                      <th>
                        Order By <br></br>Settle to Villa
                      </th>
                      <th>
                        Total <br></br>Tip Amount
                      </th>
                      <th>
                        Order By <br></br>Split Bill
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{historyOrders?.totalOrders}</td>
                      <td>{historyOrders?.totalCancelOrders}</td>
                      <td>{historyOrders?.totalSpanOrders}</td>
                      <td>{historyOrders?.totalCreditCard}</td>
                      <td>{historyOrders?.totalCrystalOrder}</td>
                      <td>{historyOrders?.totalByCash}</td>
                      <td>{historyOrders?.totalSettleToVillaOrders}</td>
                      <td>{historyOrders?.totalTip.toFixed(0)}</td>
                      <td>{historyOrders?.totalSplitOrders}</td>
                    </tr>
                  </tbody>
                </Table>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Order ID </th>
                      <th>eCode</th>
                      <th>Order Date</th>
                      <th>Booking Date</th>
                      <th>Completed Date</th>
                      <th>Tip</th>
                      <th>Transaction ID</th>
                      <th>Payment Type</th>
                      <th>Sub Total</th>
                      <th>VAT</th>
                      <th>Total with VAT</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyOrders.SpaorderHistory.map((order, index) => {
                      return <HistoryGrid key={index} order={order} />;
                    })}
                  </tbody>
                </Table>
                <Pagination className="paginationInfo">
                  <Pagination.Prev
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {paginationItems}
                  <Pagination.Next
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
}
