import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './component/Layout';
import SignIn from './component/SignIn';
import Home from './component/Home';
import ProductsHome from './component/ProductsHome';
import RequiredAuth from './component/RequireAuth';
import Logout from './component/Logout';
import NotFound from './component/HTML/NotFound';
import HistorySpa from './component/HIstory/HistorySpa';
import ScheduleHome from './component/ScheduleHome';

//const ENV = process.env.REACT_APP_ENV;
// App
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<RequiredAuth />}>
          <Route path="/" element={<Home />} />
          <Route path="orders" element={<Home />} />
          <Route path="products" element={<ProductsHome />} />
          <Route path="schedule" element={<ScheduleHome />} />
          <Route path="history/spa" element={<HistorySpa />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Route>
      <Route path="login" element={<SignIn />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
