import React, { useState } from 'react';
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import moment from 'moment';
import withReactContent from 'sweetalert2-react-content';
import { SPA_FIREBASE_FCM } from '../../context/globals';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import Pay from '../Payment/Pay';
import Tip from './components/Tip';
import ViewReceipt from './components/Modals/ViewReceipt';
import ViewInfo from './components/Modals/ViewInfo';
import useAuth from '../../hooks/useAuth';
import { deviceId } from '../../context/firestore';
import Loading from '../Loading/Loading';
import {
  IoInformationCircle,
  IoCloseCircleSharp,
  IoLogIn,
  IoPrintOutline,
  IoCheckmarkCircle,
  IoTime,
  IoCloseSharp,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import db from '../../context/firestore';
//const ENV = process.env.REACT_APP_ENV;
const SPA_FIREBASE_URL = process.env.REACT_APP_SPA_FIREBASE_URL;
const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;

export default function ConfirmOrder({ order }) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const token = auth.access_token;
  const cashier_id = auth.ecode;
  const MySwal = withReactContent(Swal);
  const [show, setShow] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);
  // Payment Setup
  const [payment, setPayment] = useState('span');

  // Modal Closing Funcs
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseOrder = () => {
    setShowOrder(false);
    setPayment('span');
  };
  const handleCloseReceipt = () => setShowReceipt(false);
  const handleShowReceipt = () => setShowReceipt(true);
  const handleShowOrder = () => setShowOrder(true);
  // Reject Order
  const rejectOrder = () => {
    MySwal.fire({
      input: 'textarea',
      inputLabel: 'Do you want to cancel Order No(' + order.id + ')?',
      inputPlaceholder: 'Type Reason to Cancel...',
      inputAttributes: {
        'aria-label': 'Type Reason to Cancel',
      },
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const reason = result.value;
        getOrderByID(order.id).then(async (response) => {
          let orderFinal = response;
          orderFinal.status = 'r';
          orderFinal.id = order.id;
          orderFinal.datetime_rejected = new Date()
            .toISOString()
            .replace('Z', '')
            .replace('T', ' ');
          // Voucher Date
          if (orderFinal.datetime_voucher) {
            orderFinal.datetime_voucher = new Date(
              orderFinal.datetime_voucher.seconds * 1000
            )
              .toISOString()
              .replace('Z', '')
              .replace('T', ' ');
          } else {
            orderFinal.datetime_voucher = null;
          }
          // Approved Submitted
          if (orderFinal.datetime_submitted) {
            orderFinal.datetime_submitted = new Date(
              orderFinal.datetime_submitted.seconds * 1000
            )
              .toISOString()
              .replace('Z', '')
              .replace('T', ' ');
          } else {
            orderFinal.datetime_submitted = null;
          }
          orderFinal.cancel_reason = reason;
          orderFinal.cashier_id = cashier_id;

          // Approved Date
          if (orderFinal.datetime_approved) {
            orderFinal.datetime_approved = new Date(
              orderFinal.datetime_approved.seconds * 1000
            )
              .toISOString()
              .replace('Z', '')
              .replace('T', ' ');
          } else {
            orderFinal.datetime_approved = null;
          }
          orderFinal = JSON.stringify(response);
          console.log(orderFinal);
          if (token) {
            const URL = SPA_SERVER_URL + 'api/spa/closeorder';
            const options = {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + token,
                deviceid: deviceId,
                'Content-Type':
                  'application/x-www-form-urlencoded;charset=UTF-8',
              },
              body: 'request=' + orderFinal,
            };

            const response = await fetch(URL, options);
            const responseJson = await response.json();
            if (responseJson.response === true) {
              toast.success(responseJson.message);
              await deleteDoc(doc(db, SPA_FIREBASE_URL, order.id.toString()));
              handleClose();
              // Firebase Notifications
              SPA_FIREBASE_FCM(order.id, order.ecode, 'reject');
            } else if (response.status === 401) {
              navigate('/login');
              toast.error(responseJson.message);
            } else {
              toast.error(responseJson.message);
            }
          } else {
            toast.error('You Session is Expired!');
            navigate('/login');
          }
        });
      }
    });
  };
  // getOrder after update
  const getOrderByID = async (id) => {
    const docRef = doc(db, SPA_FIREBASE_URL, id.toString());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
      // console.log('Document data:', docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  };
  //date conversion
  const convertDate = (date) => {
    if (date != null) {
      return new Date(date.seconds * 1000).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };
  // Close Order Pop Up
  const closeOrderNow = () => {
    MySwal.fire({
      title: 'Do you want to Close Order No(' + order.id + ')?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      icon: 'warning',
    }).then(async (result) => {
      if (result.isConfirmed) {
        order.payment_type.toLowerCase() === 'crystals' ||
        order.payment_type.toLowerCase() === 'credit card' ||
        order.payment_type.toLowerCase() === 'indigo wallet' ||
        order.payment_type.toLowerCase() === 'settle by voucher' ||
        order.payment_type.toLowerCase() === 'settle to villa' ||
        order.payment_type.toLowerCase() === 'settle by ledger'
          ? closeOrderDirectly()
          : handleShowOrder();
      }
    });
  };

  // Close Order Directly
  const closeOrderDirectly = () => {
    console.log('closing Directly');
    // New
    let paymentmethod = order.payment_type;
    let payment_detail = [];
    if (
      paymentmethod === 'Credit Card' ||
      paymentmethod === 'Crystals' ||
      paymentmethod === 'Indigo Wallet' ||
      paymentmethod === 'Settle To Villa' ||
      paymentmethod === 'Settle By Ledger' ||
      paymentmethod === 'Settle by Voucher'
    ) {
      payment_detail.push({
        id: paymentmethod === 'Credit Card' ? order.checkout_id.slice(0, 6) : 0,
        paymentmethod:
          paymentmethod === 'Credit Card'
            ? order.payment_card_type
            : paymentmethod,
        amount: order.total,
      });
    }
    if (paymentmethod !== null || paymentmethod !== undefined) {
      // Check Empty Payment Methods
      callbackFunction(payment_detail, paymentmethod);
    }
  };
  const callbackFunction = async (payment_detail, main_payment_method) => {
    setLoading(true);
    getOrderByID(order.id).then(async (response) => {
      let orderFinal = response;
      orderFinal.id = order.id;
      orderFinal.payment_detail = payment_detail;
      orderFinal.payment_type = main_payment_method;
      orderFinal.cashier_id = cashier_id;
      orderFinal.status = 'c';
      // Datetime Submitted
      if (orderFinal.datetime_submitted) {
        orderFinal.datetime_submitted = new Date(
          orderFinal.datetime_submitted.seconds * 1000
        )
          .toISOString()
          .replace('Z', '')
          .replace('T', ' ');
      } else {
        orderFinal.datetime_submitted = null;
      }
      // Voucher Date
      if (orderFinal.datetime_voucher) {
        orderFinal.datetime_voucher = new Date(
          orderFinal.datetime_voucher.seconds * 1000
        )
          .toISOString()
          .replace('Z', '')
          .replace('T', ' ');
      } else {
        orderFinal.datetime_voucher = null;
      }

      // Approved Date
      if (orderFinal.datetime_approved) {
        orderFinal.datetime_approved = new Date(
          orderFinal.datetime_approved.seconds * 1000
        )
          .toISOString()
          .replace('Z', '')
          .replace('T', ' ');
      } else {
        orderFinal.datetime_approved = null;
      }
      // Datetime Closed
      orderFinal.datetime_closed = new Date()
        .toISOString()
        .replace('Z', '')
        .replace('T', ' ');

      orderFinal = JSON.stringify(response);
      console.log(orderFinal);
      if (token) {
        const URL = SPA_SERVER_URL + 'api/spa/closeorder';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            deviceid: deviceId,
            Authorization: 'Bearer ' + token,
          },
          body: 'request=' + orderFinal,
        };

        const response = await fetch(URL, options);

        const responseJson = await response.json();
        if (responseJson.response === true) {
          setLoading(false);
          Swal.fire('Order Closed!', responseJson.message, 'success');

          var now = moment().format('YYYY-MM-DD');
          var end = order.booking_date;
          console.log(order.is_advance && now < end);
          if (order.is_advance && now < end) {
            const docRef = doc(db, SPA_FIREBASE_URL + order.id.toString());
            await updateDoc(docRef, {
              is_hide: true,
            });
          } else {
            await deleteDoc(doc(db, SPA_FIREBASE_URL, order.id.toString()));
          }
          handleCloseOrder();
        } else if (response.status === 401) {
          setLoading(false);
          navigate('/login');
          toast.error(responseJson.message);
        } else {
          setLoading(false);
          toast.error(responseJson.message);
        }
      } else {
        setLoading(false);
        toast.error('Your Session is Expired');
        navigate('/login');
      }
    });
  };

  // Remove Closed Order

  const removeClosedOrder = async (id) => {
    MySwal.fire({
      title: 'Do you want to Remove this Order',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      icon: 'warning',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await deleteDoc(doc(db, SPA_FIREBASE_URL, id.toString()));
        setLoading(false);
        toast.success('Order Removed Successfully!');
      }
    });
  };

  const handleChange = (e) => {
    const pay = e.target.value;
    setPayment(pay);
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Col className='order px-3' id={`${order.id}`}>
            {order.is_hide ? (
              <Row>
                <Col>
                  <span className='closed-tag'>Closed</span>
                  <IoCloseSharp
                    className='icon-class close-btn'
                    onClick={() => removeClosedOrder(order.id)}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row>
              <Col sm={6} className='order-id'>
                OrderID: {order.id}
              </Col>
              <Col sm={6} className='order-total'>
                Total: {order.total.toFixed(2)} SAR
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Name:</b>
              </Col>
              <Col sm={10} className='name'>
                {order.name}
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Contact:</b>
              </Col>
              <Col sm={4}>{order.contact}</Col>
              <Col sm={2}>
                <b>eCode:</b>
              </Col>
              <Col sm={4}>{order.ecode}</Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Payment:</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order.payment_type}
                {order.payment_type === 'Credit Card' ? (
                  <span className='confirmBtn'>
                    {order.payment_status === 'success' ? (
                      <IoCheckmarkCircle className='icon-class success_icon' />
                    ) : order.payment_status === 'pending' ? (
                      <>
                        <IoTime className='icon-class pending_icon' />
                      </>
                    ) : order.payment_status === 'reject' ? (
                      <IoCloseCircleSharp className='icon-class reject_icon' />
                    ) : (
                      ''
                    )}
                  </span>
                ) : (
                  ''
                )}
              </Col>
              <Col sm={2}>
                <b>Ordered:</b>
              </Col>
              <Col sm={4} className='text-capitalize date-class'>
                {convertDate(order.datetime_submitted)}
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Type:</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order.type ? order.type : 'N/A'}
              </Col>
              <Col sm={2}>
                <b>Address:</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order?.address ? (
                  <span>{order.address}</span>
                ) : order?.room_type ? (
                  <span>
                    {order.room_type.toLocaleString() === 'couple room'
                      ? 'Couple (Husband & Wife)'
                      : order.room_type}
                  </span>
                ) : (
                  'N/A'
                )}
                {/* {order?.room_type
              ? order.address
              : order?.room_type
              ? order.room_type
              : 'N/A'} */}
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}></Col>
              <Col sm={4} className='text-capitalize'></Col>
              <Col sm={2}>
                <b>Booking</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order?.booking_date}
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 12, offset: 0 }} className='mt-4 inner_btn'>
                {!order.is_hide ? (
                  <>
                    <Button
                      className='order-btn m-1'
                      variant='light'
                      onClick={closeOrderNow}
                    >
                      <IoLogIn className='icon-class' />
                      Close Order
                    </Button>
                    <Button
                      className='order-btn m-1'
                      variant='light'
                      onClick={rejectOrder}
                    >
                      <IoCloseCircleSharp className='icon-class' />
                      Cancel Order
                    </Button>
                  </>
                ) : (
                  ''
                )}

                <Button
                  className='order-btn m-1'
                  variant='light'
                  onClick={handleShow}
                >
                  <IoInformationCircle className='icon-class' />
                  View Info
                </Button>
                <Button
                  className='order-btn m-1'
                  variant='light'
                  onClick={handleShowReceipt}
                >
                  <IoPrintOutline className='icon-class' />
                  View Receipt
                </Button>
              </Col>
            </Row>
          </Col>
          {/* Modal Order Close*/}
          <Modal
            show={showOrder}
            size='lg'
            onHide={handleCloseOrder}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton className='modal-header-dark'>
              <Modal.Title className='modal-header-title'>
                Order Info
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='mx-3'>
              <Row className='p-2'>
                <Col sm={3}>
                  <b>Order ID:</b>
                </Col>
                <Col sm={3}>{order.id}</Col>
                <Col sm={3}>
                  <b>eCode:</b>
                </Col>
                <Col sm={3}>{order.ecode}</Col>
              </Row>

              <Row className='p-2'>
                <Col sm={3}>
                  <b>Sub Total:</b>
                </Col>
                <Col sm={3} className='text-capitalize'>
                  {order.subtotal.toFixed(2)} SAR
                </Col>
                <Col sm={3}>
                  <b>Total:</b>
                </Col>
                <Col className='text-capitalize' sm={3}>
                  <span className='fw-bold fs-5'>
                    {order.total.toFixed(2)} SAR
                  </span>
                </Col>
              </Row>
              <Row className='p-2'>
                <Col sm={3}>
                  <b>Payment By:</b>
                </Col>
                <Col sm={3} className='text-capitalize'>
                  {order.payment_type}
                </Col>
                <Col sm={3}>
                  <b>Room Type:</b>
                </Col>
                <Col className='text-capitalize' sm={3}>
                  {order.room_type}
                </Col>
              </Row>
              <Row className='p-2'>
                <Col sm={3}>
                  <b>Voucher:</b>
                </Col>
                <Col sm={3}>{order.isvoucher ? 'Yes' : 'N/A'}</Col>
                <Col sm={3}>
                  <b>Tip:</b>
                </Col>
                <Col sm={3}>
                  {order.tip || order.tip === 0 ? order.tip.toFixed(2) : 'N/A'}{' '}
                  SAR
                </Col>
              </Row>
              <Row className='p-2'>
                <Col sm={3}>
                  <b>Voucher Amount:</b>
                </Col>
                <Col sm={3}>
                  {order.voucher_amount ? order.voucher_amount : 'N/A'}
                </Col>
                <Col sm={3}>
                  <b>VAT:</b>
                </Col>
                <Col sm={3}>{order.vat ? order.vat.toFixed(2) : 'N/A'} SAR</Col>
              </Row>
              <Row>
                <Tip
                  id={order.id}
                  ordertip={order.tip}
                  SPA_FIREBASE_URL={SPA_FIREBASE_URL}
                  SPA_SERVER_URL={SPA_SERVER_URL}
                />
              </Row>
              <Row className='mt-5'>
                <Col md={{ span: 10, offset: 2 }}>
                  <Form>
                    <Form.Check
                      defaultChecked
                      inline
                      label='Span Payment'
                      name='payment'
                      type='radio'
                      value='span'
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      label='Cash Payment'
                      name='payment'
                      type='radio'
                      value='cash'
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      label='Split Bill'
                      name='payment'
                      type='radio'
                      value='split'
                      onChange={handleChange}
                    />
                  </Form>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Pay
                  payment={payment}
                  total={order.total}
                  getPaymentDetails={callbackFunction}
                />
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={handleCloseOrder}>
                Close
              </Button>
              {/* <Button className="order-btn m-1" variant="light">
            <IoCheckmarkCircle className="icon-class" />
            Update Order
          </Button> */}
            </Modal.Footer>
          </Modal>
          {/* Modal */}

          {/* Order Info */}
          <ViewInfo order={order} handleClose={handleClose} show={show} />
          {/* Modal Receipt */}
          <ViewReceipt
            order={order}
            showReceipt={showReceipt}
            handleCloseReceipt={handleCloseReceipt}
          />
        </>
      )}
    </>
  );
}
