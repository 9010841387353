import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';

import { doc, updateDoc, getDoc } from 'firebase/firestore';

import db from '../../../context/firestore';

const REACT_APP_PRODUCT_SPA_URL = process.env.REACT_APP_PRODUCT_SPA_URL;

function ProductTip({ id, ordertip }) {
  const [tip, setTip] = useState(0);
  function changeTip(val) {
    setTip(val);
  }

  async function addTip(id) {
    const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL, id.toString());
    const docSnap = await getDoc(docRef);
    let doc1 = docSnap.data();
    let orignaltotal = parseFloat(doc1.total);

    if (tip <= 0) {
      toast.error('Tip is Invalid or not Inserted Before!');
    } else {
      var newTotal = parseFloat(orignaltotal.toFixed(2)) + parseFloat(tip);
      const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL + id.toString());
      await updateDoc(docRef, {
        tip: parseFloat(tip),
        total: parseFloat(newTotal),
      });
      console.log(newTotal);
    }
  }
  async function removeTip(id) {
    const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL, id.toString());
    const docSnap = await getDoc(docRef);
    let doc1 = docSnap.data();
    let orignaltip = doc1.tip;

    if (orignaltip <= 0 && orignaltip !== undefined) {
      toast.error('Tip is Invalid or not Inserted Before!');
    } else {
      let orignaltotal = doc1.total;
      var newTotal = parseFloat(
        orignaltotal.toFixed(2) - parseFloat(orignaltip),
      );
      const docRef = doc(db, REACT_APP_PRODUCT_SPA_URL + id.toString());
      await updateDoc(docRef, {
        tip: 0,
        total: newTotal,
      });
    }
  }
  return (
    <Col>
      <Row>
        {ordertip !== 0 ? (
          <Col className="mt-4 d-flex align-items-end flex-column">
            <Button
              className="remove-btn btn btn-danger btn-sm m-1"
              onClick={() => removeTip(id)}
            >
              Remove Tip
            </Button>
          </Col>
        ) : (
          <Col sm={12} className="mt-4">
            <Row>
              <Col sm={7}>
                <Form.Label>Add Tip</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  placeholder="Enter Tip Amount"
                  value={tip}
                  onChange={(e) => {
                    changeTip(e.target.value);
                  }}
                />
              </Col>
              <Col sm={5} className="mt-4">
                <Button
                  className="add-btn btn btn-info btn-sm mt-2"
                  onClick={() => addTip(id)}
                >
                  Add Tip
                </Button>
                <Button
                  className="remove-btn btn btn-danger btn-sm mt-2 mx-2"
                  onClick={() => removeTip(id)}
                >
                  Remove Tip
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Col>
  );
}

export default ProductTip;
