import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Animated } from 'react-animated-css';

const Cash = ({ payment, parentCallback, total }) => {
  const [cash, setCash] = useState('');
  const [cashError, setCashError] = useState('');
  const sendDetails = () => {
    let payment_detail = [];
    if (cash !== '' && cash !== null) {
      payment_detail.push({
        id: 0,
        paymentmethod: payment,
        amount: cash,
      });
      const main_payment_method = 'cash';
      setCashError('');
      parentCallback(payment_detail, main_payment_method);
    } else {
      setCashError('Cash Field is Empty');
    }
  };

  return (
    <Animated animationIn="zoomIn" animationOut="zoomOut" isVisible={true}>
      <Row className="mt-3">
        <Col md={4}>
          <Form.Label className="font-weight-bold">Cash Amount</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            type="number"
            min={0}
            placeholder="Enter Cash Amount"
            value={cash}
            onChange={(e) => {
              setCash(e.target.value);
              setCashError('');
            }}
          />
          <Form.Text className="text-danger">{cashError}</Form.Text>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            className="order-btn mr-0 float-right"
            variant="dark"
            onClick={sendDetails}
          >
            Close Order
          </Button>
        </Col>
      </Row>
    </Animated>
  );
};

export default Cash;
