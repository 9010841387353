import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './HTML/Sidebar';

function Layout() {
  return (
    <div className="App">
      <Container>
        <ToastContainer />
        <Sidebar />
        <Outlet />
      </Container>
    </div>
  );
}

export default Layout;
