import React from 'react';
import { Col, Row, Button, Badge, Modal, Table } from 'react-bootstrap';
import RawInfo from '../RawInfo';

function ViewInfoHistory({ order, handleClose, show }) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="modal-header-dark">
        <Modal.Title className="modal-header-title">Order Info</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <RawInfo order={order} />
        <Row className="mt-3">
          <Col sm={3}>
            <b>Assigned Room:</b>
          </Col>
          <Col sm={3} className="text-capitalize">
            {order.assigned_room ? (
              <Badge pill bg="dark" className="m-1 p-2">
                {order.assigned_room}
              </Badge>
            ) : (
              'No Staff Selected.'
            )}
          </Col>
          <Col sm={3}>
            <b>Assigned Couple Room:</b>
          </Col>
          <Col sm={3} className="text-capitalize">
            {order.assigned_couple_room ? (
              <Badge pill bg="dark" className="m-1 p-2">
                {order.assigned_couple_room}
              </Badge>
            ) : (
              'No Staff Selected.'
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          {order.preferred_staff ? (
            <>
              <Col sm={6}>
                <b>Assigned Staff:</b>
              </Col>
              <Col sm={6} className="text-capitalize">
                {order.preferred_staff ? (
                  <Badge pill bg="dark" className="m-1 p-2">
                    {order.preferred_staff.empName}-
                    {order.preferred_staff.empCode}
                  </Badge>
                ) : (
                  'No Staff Selected.'
                )}
              </Col>
            </>
          ) : (
            ''
          )}
          {order.preferred_couple_staff ? (
            <>
              <Col sm={6}>
                <b>Assigned Staff Couple:</b>
              </Col>
              <Col sm={6} className="text-capitalize">
                <Badge pill bg="dark" className="m-1 p-2">
                  {order.preferred_couple_staff.empName}-
                  {order.preferred_couple_staff.empCode}
                </Badge>
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
        {order.room_type?.toLowerCase() === 'facility' ? (
          <Row className="mt-3">
            <Col sm={3}>
              <b>Facility Slots:</b>
            </Col>
            <Col sm={9} className="text-capitalize">
              {order.facility_time_slot?.map((item, index) => {
                return (
                  <Badge pill bg="dark" className="m-1 p-2" key={index}>
                    {item.value}
                  </Badge>
                );
              })}
            </Col>
          </Row>
        ) : (
          <>
            <Row className="mt-2">
              <Col sm={12}>
                <h5>Slots</h5>
                {order.time_slot?.map((item, index) => {
                  return (
                    <Badge pill bg="dark" className="m-1 p-2" key={index}>
                      {item.value}
                    </Badge>
                  );
                })}
              </Col>
            </Row>
          </>
        )}

        <Row className="p-3">
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>Item</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-weight-bold">Sub Total</td>
                <td className="font-weight-bold">
                  {order.subtotal.toFixed(2)} SAR
                </td>
              </tr>
              <tr>
                <td>Vat</td>
                <td>{order.vat.toFixed(2)} SAR</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Total</td>
                <td className="font-weight-bold">
                  {order.total.toFixed(2)} SAR
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewInfoHistory;
