import React, { useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

function Logout() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';
  useEffect(() => {
    if (auth) {
      setAuth({});
      localStorage.removeItem('data');
      localStorage.removeItem('slots');
      localStorage.removeItem('staff');
      navigate('/login', { replace: true });
    } else {
      navigate(from, { replace: true });
    }
  }, [auth]);

  return <div></div>;
}

export default Logout;
