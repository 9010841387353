import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getInstallations, getId } from '@firebase/installations';
import sha1 from 'sha1';

const firebaseConfig = {
  apiKey: 'AIzaSyA8c1HClIfoMa0oRRlpVjwYsC13dVRlomU',
  authDomain: 'foodnbeverages-c1c44.firebaseapp.com',
  databaseURL: 'https://foodnbeverages-c1c44.firebaseio.com',
  projectId: 'foodnbeverages-c1c44',
  storageBucket: 'foodnbeverages-c1c44.appspot.com',
  messagingSenderId: '640296424944',
  appId: '1:640296424944:web:7c479b374e1efb83013935',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
//Get Device Id

const installation = getInstallations(app);
export const deviceId = sha1(getId(installation));
export default db;
