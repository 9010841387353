import React, { useState } from 'react';
import { Col, Row, Button, Modal, Badge } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import ViewReceipt from './components/Modals/ViewReceipt';
import ViewInfo from './components/Modals/ViewInfo';
import { SPA_FIREBASE_FCM } from '../../context/globals';
import db from '../../context/firestore';
import RoomComponent from './components/RoomComponent';
import useAuth from '../../hooks/useAuth';
import { deviceId } from '../../context/firestore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading/Loading';
import {
  IoInformationCircle,
  IoCloseCircleSharp,
  IoCheckmarkCircle,
  IoTime,
  IoSyncSharp,
} from 'react-icons/io5';
import {
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import RawInfo from './components/RawInfo';

//const ENV = process.env.REACT_APP_ENV;
const SPA_FIREBASE_URL = process.env.REACT_APP_SPA_FIREBASE_URL;
const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;

export default function Order({ order }) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const token = auth.access_token; // Solve
  const cashier_id = auth.ecode;
  const MySwal = withReactContent(Swal);
  const [show, setShow] = useState(false);
  const [showStaff, setShowStaff] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Staff Modal
  const handleCloseStaff = () => setShowStaff(false);
  const handleShowStaff = () => setShowStaff(true);
  // Receipt Modal
  const handleCloseReceipt = () => setShowReceipt(false);
  const handleShowReceipt = () => setShowReceipt(true);

  const rejectOrder = () => {
    MySwal.fire({
      input: 'textarea',
      inputLabel: 'Do you want to cancel Order No(' + order.id + ')?',
      inputPlaceholder: 'Type Reason to Cancel...',
      inputAttributes: {
        'aria-label': 'Type Reason to Cancel',
      },
      showCancelButton: true,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const reason = result.value;
          getOrderByID(order.id).then(async (response) => {
            let orderFinal = response;
            orderFinal.status = 'r';
            orderFinal.id = order.id;
            orderFinal.datetime_rejected = new Date()
              .toISOString()
              .replace('Z', '')
              .replace('T', ' ');
            // Voucher Date
            if (orderFinal.datetime_voucher) {
              orderFinal.datetime_voucher = new Date(
                orderFinal.datetime_voucher.seconds * 1000
              )
                .toISOString()
                .replace('Z', '')
                .replace('T', ' ');
            } else {
              orderFinal.datetime_voucher = null;
            }
            orderFinal.cancel_reason = reason;
            orderFinal.cashier_id = cashier_id;
            if (orderFinal.datetime_submitted) {
              orderFinal.datetime_submitted = new Date(
                orderFinal.datetime_submitted.seconds * 1000
              )
                .toISOString()
                .replace('Z', '')
                .replace('T', ' ');
            } else {
              orderFinal.datetime_submitted = null;
            }
            orderFinal = JSON.stringify(response);
            //console.log(deviceId, token);
            if (token) {
              const URL = SPA_SERVER_URL + 'api/spa/closeorder';
              const options = {
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + token,
                  deviceid: deviceId,
                  'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: 'request=' + orderFinal,
              };

              const response = await fetch(URL, options);
              const responseJson = await response.json();
              if (response.status === 200) {
                toast.success(responseJson.message);
                await deleteDoc(doc(db, SPA_FIREBASE_URL, order.id.toString()));
                // Firebase Notifications
                SPA_FIREBASE_FCM(order.id, order.ecode, 'reject');
              } else if (response.status === 401) {
                navigate('/logout');
                toast.error(responseJson.message);
              } else {
                toast.error(responseJson.message);
              }
            } else {
              toast.error('You Session is Expired!');
              navigate('/logout');
            }
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getOrderByID = async (id) => {
    const docRef = doc(db, SPA_FIREBASE_URL, id.toString());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      return { ...docSnap.data(), id: docSnap.id };
      // return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  };

  const updateOrderStatus = async (id) => {
    const docRef = doc(db, SPA_FIREBASE_URL + id.toString());
    await updateDoc(docRef, {
      status: 'a',
      datetime_approved: serverTimestamp(),
    });
    toast.success('Order Confirmed');
    handleCloseStaff();
    // Firebase Notifications
    if (token) {
      SPA_FIREBASE_FCM(order.id, order.ecode, 'deliver');
    } else {
      toast.error('Your Session is Expired!');
      navigate('/logout');
    }
  };
  // convert datatime
  const updateOrder = async (id) => {
    const docRef = doc(db, SPA_FIREBASE_URL + id.toString());
    const docSnap = await getDoc(docRef);
    const dataCom = docSnap.data();

    if (
      (order.payment_type === 'Credit Card') &
      (order.payment_status !== 'success')
    ) {
      toast.error('Credit Card Payment is not Successful!');
      return;
    }

    if (
      order.type?.toLowerCase() === 'villa' ||
      order.type?.toLowerCase() === 'outdoor' ||
      order.room_type?.toLowerCase() === 'salon'
    ) {
      // Check Only Staff
      if (dataCom.preferred_staff.empName) {
        updateOrderStatus(id);
      } else {
        toast.error('Staff is not Selected');
      }
    } else if (order.room_type?.toLowerCase() === 'single room') {
      // if single room
      if (dataCom.preferred_staff.empName && dataCom.assigned_room) {
        updateOrderStatus(id);
      } else {
        toast.error('Please Select Staff & Room.');
      }
    } else if (order.room_type?.toLowerCase() === 'couple room') {
      if (
        dataCom.preferred_staff.empName &&
        dataCom.preferred_couple_staff.empName &&
        dataCom.assigned_room &&
        dataCom.assigned_couple_room
      ) {
        updateOrderStatus(id);
      } else {
        toast.error('Please Select Staff & Rooms.');
      }
    } else if (order.room_type?.toLowerCase() === 'facility') {
      updateOrderStatus(id);
    } else {
      // No validation
      toast.error(
        'You are confirming Order From a service which is not available'
      );
      // updateOrderStatus(id);
    }
  };
  const convertDate = (date) => {
    if (date != null) {
      return new Date(date.seconds * 1000).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  const checkPaymentStatus = async () => {
    setLoading(true);
    if (token) {
      const data = {
        id: order.checkout_id,
        cardtype: order.payment_card_type,
        ecode: order.ecode,
      };
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          deviceid: deviceId,
        },
      };

      var URL = SPA_SERVER_URL + 'api/payment/checkPaymentStatus';
      await axios
        .post(URL, data, config)
        .then(async (response) => {
          if (response.status === 200) {
            setLoading(false);
            if (response.data.response === true) {
              const docRef = doc(db, SPA_FIREBASE_URL + order.id.toString());
              await updateDoc(docRef, {
                payment_status: response.data.paymentStatus,
              });
            } else {
              toast.warning(response.data.msg);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          if (error.response.status === 400 || error.response.status === 401) {
            navigate('/login');
          }
        });
    } else {
      toast.error('You Session is Expired!');
      navigate('/logout');
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {order.payment_type === 'Credit Card' &&
          order.payment_status === 'reject' ? (
            <div className='payment_reason_div'>
              {order.payment_reject_reason}
            </div>
          ) : (
            ''
          )}
          <Col className='order px-3' id={`${order.id}`}>
            <Row>
              <Col sm={6} className='order-id'>
                OrderID: {order.id}
              </Col>
              <Col sm={6} className='order-total'>
                Total: {order.total.toFixed(2)} SAR
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Name:</b>
              </Col>
              <Col sm={10} className='name'>
                {order.name}
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Contact:</b>
              </Col>
              <Col sm={4}>{order.contact ? order.contact : 'N/A'}</Col>
              <Col sm={2}>
                <b>eCode:</b>
              </Col>
              <Col sm={4}>{order.ecode ? order.ecode : 'N/A'}</Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Payment:</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order.payment_type}
                {order.payment_type === 'Credit Card' ? (
                  <span className='confirmBtn'>
                    {order.payment_status === 'success' ? (
                      <IoCheckmarkCircle className='icon-class success_icon' />
                    ) : order.payment_status === 'pending' ? (
                      <>
                        <IoTime className='icon-class pending_icon' />
                        <IoSyncSharp
                          className='icon-class'
                          onClick={checkPaymentStatus}
                        />
                      </>
                    ) : order.payment_status === 'reject' ? (
                      <IoCloseCircleSharp className='icon-class reject_icon' />
                    ) : (
                      <IoSyncSharp
                        className='icon-class'
                        onClick={checkPaymentStatus}
                      />
                    )}
                  </span>
                ) : (
                  ''
                )}
              </Col>
              <Col sm={2}>
                <b>Ordered:</b>
              </Col>
              <Col sm={4} className='text-capitalize date-class'>
                {convertDate(order.datetime_submitted)}
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}>
                <b>Type:</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order.type ? order.type : 'N/A'}
              </Col>
              <Col sm={2}>
                <b>Address:</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {/* {order?.address
              ? order.address
              : order?.room_type
              ? order.room_type
              : 'N/A'} */}
                {order?.address ? (
                  <span>{order.address}</span>
                ) : order?.room_type ? (
                  <span>
                    {order.room_type.toLocaleString() === 'couple room'
                      ? 'Couple (Husband & Wife)'
                      : order.room_type}
                  </span>
                ) : (
                  'N/A'
                )}
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col sm={2}></Col>
              <Col sm={4} className='text-capitalize'></Col>
              <Col sm={2}>
                <b>Booking</b>
              </Col>
              <Col sm={4} className='text-capitalize'>
                {order?.booking_date}
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 12, offset: 0 }} className='mt-4 inner_btn'>
                <Button
                  className={`order-btn m-1 ${
                    order.payment_type === 'Credit Card' &&
                    order.payment_status !== 'success'
                      ? 'disabled'
                      : ''
                  }`}
                  variant='light'
                  onClick={handleShowStaff}
                >
                  <IoCheckmarkCircle className='icon-class' />
                  Confirm Order
                </Button>
                <Button
                  className='order-btn m-1'
                  variant='light'
                  onClick={rejectOrder}
                >
                  <IoCloseCircleSharp className='icon-class' />
                  Cancel Order
                </Button>
                <Button
                  className='order-btn m-1'
                  variant='light'
                  onClick={handleShow}
                >
                  <IoInformationCircle className='icon-class' />
                  View Info
                </Button>
                <Button
                  className='order-btn m-1'
                  variant='light'
                  onClick={handleShowReceipt}
                >
                  <IoInformationCircle className='icon-class' />
                  View Receipt
                </Button>
              </Col>
            </Row>
          </Col>
          {/* Order Info */}
          <ViewInfo order={order} handleClose={handleClose} show={show} />

          {/* Modal Confirm and update Staff */}
          <Modal
            show={showStaff}
            size='lg'
            onHide={handleCloseStaff}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton className='modal-header-dark'>
              <Modal.Title className='modal-header-title'>
                Confirm Order
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='mx-3'>
              <RawInfo order={order} />

              {order.room_type?.toLowerCase() === 'facility' ? (
                <Row className='mt-3'>
                  <Col sm={3}>
                    <b>Facility Slots:</b>
                  </Col>
                  <Col sm={9} className='text-capitalize'>
                    {order.facility_time_slot?.map((item, index) => {
                      return (
                        <Badge pill bg='dark' className='m-1 p-2' key={index}>
                          {item.value}
                        </Badge>
                      );
                    })}
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className='mt-3'>
                    {/* Selection Line */}
                    <Row className='mt-4'>
                      <Col>
                        <hr className='my-0'></hr>
                        <b className='purple'>User Selection</b>
                        <hr className='my-0'></hr>
                      </Col>
                    </Row>
                    {/* Selection Line */}
                    <Row className='mt-3'>
                      <Col sm={3}>
                        <b>Staff:</b>
                      </Col>
                      <Col sm={9} className='text-capitalize'>
                        {order.preferred_staff.empName}
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col sm={3}>
                        <b>Slots Selected:</b>
                      </Col>
                      <Col sm={9} className='text-capitalize'>
                        <Row>
                          {order.time_slot.map((slotItem, index) => {
                            return (
                              <Col
                                sm={3}
                                className='m-1 slot-count'
                                key={index}
                              >
                                <Badge pill bg='dark' className='mx-2 p-2'>
                                  {slotItem.value}
                                </Badge>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                    {order.room_type?.toLowerCase() === 'single room' ||
                    order.room_type?.toLowerCase() === 'couple room' ? (
                      <>
                        <Row className='mt-3'>
                          <RoomComponent type='single' id={order.id} />
                        </Row>
                      </>
                    ) : (
                      ''
                    )}

                    {order.room_type?.toLowerCase() === 'couple room' ? (
                      <>
                        {/* Selection Line */}
                        <Row className='mt-4'>
                          <Col>
                            <hr className='my-0'></hr>
                            <b className='purple'>Couple Selection</b>
                            <hr className='my-0'></hr>
                          </Col>
                        </Row>
                        {/* Selection Line */}
                        <Row className='mt-3'>
                          <Col sm={3}>
                            <b>Couple Staff:</b>
                          </Col>
                          <Col sm={9} className='text-capitalize'>
                            {order.preferred_couple_staff?.empName}
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <Col sm={3}>
                            <b>Slots Selected:</b>
                          </Col>
                          <Col sm={9} className='text-capitalize'>
                            <Row>
                              {order.time_slot.map((slotItem, index) => {
                                return (
                                  <Col
                                    sm={3}
                                    className='m-1 slot-count'
                                    key={index}
                                  >
                                    <Badge pill bg='dark' className='mx-2 p-2'>
                                      {slotItem.value}
                                    </Badge>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                        <Row className='mt-3'>
                          <RoomComponent type='couple' id={order.id} />
                        </Row>
                      </>
                    ) : (
                      ''
                    )}
                  </Row>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={handleCloseStaff}>
                Close
              </Button>
              <Button
                className='order-btn m-1'
                variant='light'
                onClick={() => updateOrder(order.id)}
              >
                <IoCheckmarkCircle className='icon-class' />
                Confirm Order
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Modal */}
          {/* Modal Receipt View */}
          <ViewReceipt
            order={order}
            showReceipt={showReceipt}
            handleCloseReceipt={handleCloseReceipt}
          />
        </>
      )}
    </>
  );
}
