import React, { useState } from 'react';
import moment from 'moment';
import { Button, Badge } from 'react-bootstrap';
import ViewReceipt from '../Orders/components/Modals/ViewReceipt';
import ViewInfoHistory from '../Orders/components/Modals/ViewInfoHistory';
import { IoInformationCircle, IoPrintOutline } from 'react-icons/io5';

function HistoryGrid({ order }) {
  const [orderNew, setOrder] = useState(JSON.parse(order.orderjson));

  const [show, setShow] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const handleCloseReceipt = () => setShowReceipt(false);
  const handleShowReceipt = () => setShowReceipt(true);
  // View Info Handle

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(orderNew);
  return (
    <>
      <tr key={order.id}>
        <td>{order?.id}</td>
        <td>{orderNew?.ecode}</td>
        <td>
          {moment(orderNew?.datetime_submitted).format('YYYY-MM-DD h:mm')}
        </td>
        <td>{moment(orderNew?.booking_date).format('YYYY-MM-DD')}</td>
        <td>{moment(orderNew?.datetime_closed).format('YYYY-MM-DD')}</td>
        <td>{order?.tip}</td>
        <td>
          {order?.payment_detail.length > 0
            ? order?.payment_detail.map((item, index) => {
                return (
                  <Badge pill bg="dark" className="m-1 p-2" key={index}>
                    {item.TransactionId}
                  </Badge>
                );
              })
            : 'N/A'}
        </td>
        {/* <td>{order?.payment_detail[0]?.TransactionId ?? 'N/A'}</td> */}
        <td>{orderNew?.payment_type ?? 'N/A'}</td>
        <td>{orderNew?.subtotal ?? 'N/A'}</td>
        <td>{orderNew?.vat ?? 'N/A'}</td>
        <td>{orderNew?.total ?? 'N/A'}</td>
        <td>
          <Button
            className="order-btn m-1"
            variant="light"
            onClick={handleShow}
          >
            <IoInformationCircle className="icon-class" />
          </Button>
          <Button variant="light" id="getHistory" onClick={handleShowReceipt}>
            <IoPrintOutline className="icon-class" />
          </Button>
        </td>
      </tr>

      {/* Modal Receipt */}
      <ViewReceipt
        order={orderNew}
        showReceipt={showReceipt}
        handleCloseReceipt={handleCloseReceipt}
      />
      {/* Modal Receipt */}
      {/* Order Info History*/}
      <ViewInfoHistory order={orderNew} handleClose={handleClose} show={show} />
    </>
  );
}

export default HistoryGrid;
