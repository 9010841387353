import React from 'react';
import { Alert } from 'react-bootstrap';

function NotFound() {
  return (
    <>
      <Alert className="mt-5">Not Found</Alert>
    </>
  );
}

export default NotFound;
