import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequiredAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const nowDate = new Date().getTime();
  return auth?.access_token && nowDate ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequiredAuth;
