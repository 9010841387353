import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Animated } from 'react-animated-css';

const Span = ({ payment, parentCallback, total }) => {
  const [transID, setTransID] = useState('');
  const [transIDError, setTransIDError] = useState('');
  const [payBy, setPayBy] = useState('');
  const [payByError, setpayByError] = useState('');

  const sendDetails = () => {
    let payment_detail = [];
    if (payBy !== '') {
      setTransIDError('');
      if (transID !== '') {
        setpayByError('');
        payment_detail.push({
          id: transID,
          paymentmethod: payBy,
          amount: total,
        });
        const main_payment_method = 'span';
        parentCallback(payment_detail, main_payment_method);
      } else {
        setTransIDError('Transaction Id is Missing to Close this order');
      }
    } else {
      setpayByError('Payment Method Not Selected.');
    }
  };

  return (
    <Animated animationIn="zoomIn" animationOut="zoomOut" isVisible={true}>
      <Row className="mt-4">
        <Col md={4}>
          <Form.Label className="font-weight-bold">Payment Method</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Select
            className="me-sm-2"
            onChange={(e) => {
              setPayBy(e.target.value);
              setpayByError('');
            }}
          >
            <option value="">Select Payment Method</option>
            <option value="Visa Card">Visa Card</option>
            <option value="Master Card">Master Card</option>
            <option value="Mada Card">Mada Card</option>
          </Form.Select>
          <Form.Text className="text-danger">{payByError}</Form.Text>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Form.Label className="font-weight-bold">Transaction ID</Form.Label>
        </Col>
        <Col md={8}>
          <Form.Control
            type="text"
            placeholder="Enter Transaction Id"
            value={transID}
            onChange={(e) => {
              setTransID(e.target.value);
              setTransIDError('');
            }}
          />
          <Form.Text className="text-danger">{transIDError}</Form.Text>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            className="order-btn mr-0 float-right"
            variant="dark"
            onClick={sendDetails}
          >
            Close Order
          </Button>
        </Col>
      </Row>
    </Animated>
  );
};

export default Span;
