import React from 'react';
import { Col, Row, Button, Image, Modal, Table, Alert } from 'react-bootstrap';

// import RawInfo from '../RawInfo';

function ViewProInfo({ product, handleClose, show }) {
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="modal-header-dark">
        <Modal.Title className="modal-header-title">Product Info</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <Col>
          {product.description ? (
            <Alert variant="info">
              <span className="font-weight-bold">Description:</span>{' '}
              {product.description}
            </Alert>
          ) : (
            ''
          )}
        </Col>
        <Row className="mt-3">
          <Col sm={3}>
            <b>Name:</b>
          </Col>
          <Col sm={9} className="text-capitalize">
            {product.name ? product.name : 'N/A'}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={3}>
            <b>Order ID:</b>
          </Col>
          <Col sm={3}>{product.id}</Col>
          <Col sm={3}>
            <b>eCode:</b>
          </Col>
          <Col sm={3}>{product.ecode}</Col>
        </Row>

        <Row className="mt-4">
          <Col className="px-3">
            {/* striped bordered hover variant="dark" */}
            <Table>
              <thead className="table-dark">
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Discount</th>
                  <th>Subtotal</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {product?.products.length > 0 &&
                  product.products.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Image
                          src={item.img}
                          className="item-img"
                          loading="lazy"
                          alt=""
                        />
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.qty}</td>
                      <td>{item?.item_discount} SAR</td>
                      <td>{item?.item_subtotal} SAR</td>
                      <td>{item?.item_nettotal} SAR</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="px-3">
            <Table className="receipt-amount-table">
              <thead className="table-dark">
                <tr>
                  <th>Subtotal</th>
                  <th className="text-end">
                    {product.subtotal.toFixed(2)} SAR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VAT 15%</td>
                  <td className="text-end">{product.vat.toFixed(2)} SAR</td>
                </tr>
                <tr>
                  <td>Tip Amount</td>
                  <td className="text-end">
                    {product.tip ? product.tip.toFixed(2) : 0} SAR
                  </td>
                </tr>
                {product.discount ? (
                  <tr>
                    <td>Discount</td>
                    <td className="text-end">
                      {product.discount ? product.discount.toFixed(2) : 0} SAR -
                      (
                      {product.discount_percentage
                        ? product.discount_percentage + ' %'
                        : ''}
                      )
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
                <tr>
                  <td>Net Amount</td>
                  <td className="text-end">{product.total.toFixed(2)} SAR</td>
                </tr>
                <tr>
                  <td>Payment By</td>
                  <td className="text-end">{product.payment_type}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* <Row className="p-3">
          <Table striped bordered hover variant="light">
            <thead>
                <th>Item</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vat</td>
                <td>{product.vat.toFixed(2)} SAR</td>
              </tr>
              <tr>
                <td className="font-weight-bold">Sub Total</td>
                <td className="font-weight-bold">
                  {product.subtotal.toFixed(2)} SAR
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold">Total</td>
                <td className="font-weight-bold">
                  {product.total.toFixed(2)} SAR
                </td>
              </tr>
            </tbody>
          </Table>
        </Row> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewProInfo;
