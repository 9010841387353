import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Animated } from 'react-animated-css';
import { IoAddCircleSharp, IoRemoveCircleSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';

const Split = ({ payment, total, parentCallback }) => {
  const [validated, setValidated] = useState(false);
  const [fields, setFields] = useState([
    { amount: '', paymentmethod: '', id: '' },
    { amount: '', paymentmethod: '', id: '' },
  ]); // value can be id or cash

  const handleInputChange = (index, e) => {
    const values = [...fields];
    values[index][e.target.name] = e.target.value;
    setFields(values);
  };
  const handleSubmit = (e) => {
    let payment_detail = [];
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (fields.length >= 2) {
      const validation = fields.every(
        (field) => field.id && field.paymentmethod && field.amount,
      );
      if (validation) {
        payment_detail = fields;
        const main_payment_method = 'split bill';
        parentCallback(payment_detail, main_payment_method);
      } else {
        toast.error('There is Problem With Splitting Bill Fields');
      }
    } else {
      toast.error('There is Problem With Splitting Bill');
    }
  };
  const addFieldHandle = () => {
    setFields([...fields, { amount: '', paymentmethod: '', id: '' }]);
  };
  const removeFieldHandle = (index) => {
    let values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };
  return (
    <Animated animationIn="zoomIn" animationOut="zoomOut" isVisible={true}>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        // validated
      >
        <Row className="mt-3">
          <Row className="mt-3">
            <Col md={4}>
              <Form.Label className="font-weight-bold">Split Amount</Form.Label>
            </Col>
            <Col md={4}>
              <Form.Label className="font-weight-bold">
                Payment Method
              </Form.Label>
            </Col>
            <Col md={3}>
              <Form.Label className="font-weight-bold">
                Cash Amount/ ID
              </Form.Label>
            </Col>
            <Col md={1}>
              <Form.Label className="font-weight-bold"></Form.Label>
            </Col>
          </Row>
          {fields.map((field, index) => {
            return (
              <Row className="mt-3 fields" key={index}>
                <Col md={4}>
                  <Form.Control
                    type="number"
                    name="amount"
                    required
                    min={0}
                    value={field.paid}
                    placeholder="Enter Amount"
                    onChange={(e) => {
                      handleInputChange(index, e);
                    }}
                  />
                  <Form.Text className="text-danger"></Form.Text>
                </Col>
                <Col md={4}>
                  <Form.Control
                    required
                    as="select"
                    className="me-sm-2"
                    name="paymentmethod"
                    onChange={(e) => {
                      handleInputChange(index, e);
                    }}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="Cash">Cash</option>
                    <option value="Visa Card">Visa Card</option>
                    <option value="Master Card">Master Card</option>
                    <option value="Mada Card">Mada Card</option>
                  </Form.Control>
                  <Form.Text className="text-danger"></Form.Text>
                </Col>
                <Col md={3}>
                  <Form.Control
                    type="number"
                    min={0}
                    required
                    name="id"
                    placeholder="Amount/ ID"
                    value={field.value}
                    onChange={(e) => {
                      handleInputChange(index, e);
                    }}
                  />
                  <Form.Text className="text-danger"></Form.Text>
                </Col>

                {index === 1 ? (
                  <Col>
                    <a
                      className="icon-append"
                      variant="light"
                      onClick={addFieldHandle}
                    >
                      <IoAddCircleSharp />
                    </a>
                  </Col>
                ) : index >= 2 ? (
                  <Col>
                    <a
                      className="icon-append"
                      variant="light"
                      onClick={addFieldHandle}
                    >
                      <IoAddCircleSharp />
                    </a>
                    <a
                      className="icon-append"
                      variant="light"
                      onClick={(e) => {
                        removeFieldHandle(index);
                      }}
                    >
                      <IoRemoveCircleSharp />
                    </a>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            );
          })}
          <Row className="mt-3">
            <Col>
              <Button
                className="order-btn mr-0 float-right"
                variant="dark"
                onClick={handleSubmit}
              >
                Close Order
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
    </Animated>
  );
};

export default Split;
