import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import logo from '../../../assets/images/nakls.png';

const ReceiptPro = React.forwardRef(({ product }, ref) => {
  const convertDate = (date) => {
    if (date != null) {
      return new Date(date.seconds * 1000).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  //   function checkImage(url) {
  //     var request = new XMLHttpRequest();
  //     request.open('GET', url, true);
  //     request.send();
  //     request.onload = function () {
  //       const status = request.status;
  //       if (request.status == 200) {
  //         //if(statusText == OK)
  //         console.log('image exists');
  //       } else {
  //         console.log("image doesn't exist");
  //       }
  //     };
  //   }
  return (
    <Modal.Body className="mx-3" ref={ref}>
      <Row className="mt-3">
        <Col className="col-sm-12 d-flex justify-content-center">
          <img src={logo} alt="" width="200" className="img-fluid " />
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col className="col-sm-12 col-md-4">
          <p className="vat text-center">
            <span>VAT NO: </span>310168166700003
          </p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>Bill Id</Col>
        <Col className="text-end">{product.id}</Col>
      </Row>
      <Row className="mt-2">
        <Col>eCode</Col>
        <Col className="text-end">{product.ecode}</Col>
      </Row>
      {/* <Row className="mt-2">
        <Col>Address</Col>
        <Col className="text-end text-capitalize">
          // {product?.address
          //   ? product.address
          //   : product?.room_type
          //   ? product.room_type
          //   : 'N/A'}
            {order?.address ? (
              <span>{order.address}</span>
            ) : order?.room_type ? (
              <span>
                {order.room_type.toLocaleString() === 'couple room'
                  ? 'Couple (Husband & Wife)'
                  : order.room_type}
              </span>
            ) : (
              'N/A'
            )}
        </Col>
      </Row> */}
      {product.room_type?.toLowerCase() === 'couple room' ? (
        <Row className="mt-2">
          <Col>Couple Ecode</Col>
          <Col className="text-end text-capitalize">
            {product.couple_ecode ? product.couple_ecode : 'N/A'}
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row className="mt-2 ">
        <Col>Order Date</Col>
        <Col className="text-end text-capitalize date-class">
          {convertDate(product.datetime_submitted)}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-3">
          {/* striped bordered hover variant="dark" */}
          <Table>
            <thead className="table-dark">
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Discount</th>
                <th>Subtotal</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {product?.products.length > 0 &&
                product.products.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.item_discount} SAR</td>
                    <td>{item?.item_subtotal} SAR</td>
                    <td>{item?.item_nettotal} SAR</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-3">
          <Table className="receipt-amount-table">
            <thead className="table-dark">
              <tr>
                <th>Subtotal</th>
                <th className="text-end">{product.subtotal.toFixed(2)} SAR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>VAT 15%</td>
                <td className="text-end">{product.vat.toFixed(2)} SAR</td>
              </tr>
              <tr>
                <td>Tip Amount</td>
                <td className="text-end">
                  {product.tip ? product.tip.toFixed(2) : 0} SAR
                </td>
              </tr>
              {product.discount ? (
                <tr>
                  <td>Discount</td>
                  <td className="text-end">
                    {product.discount ? product.discount.toFixed(2) : 0} SAR - (
                    {product.discount_percentage
                      ? product.discount_percentage + ' %'
                      : ''}
                    )
                  </td>
                </tr>
              ) : (
                <tr></tr>
              )}
              <tr>
                <td>Net Amount</td>
                <td className="text-end">{product.total.toFixed(2)} SAR</td>
              </tr>
              <tr>
                <td>Payment By</td>
                <td className="text-end">{product.payment_type}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Modal.Body>
  );
});

export default ReceiptPro;
