import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import db from '../../context/firestore';
import { Row, Col, Alert } from 'react-bootstrap';
import Product from './Product';
import Loading from '../Loading/Loading';

//const ENV = process.env.REACT_APP_ENV;
const REACT_APP_PRODUCT_SPA_URL = process.env.REACT_APP_PRODUCT_SPA_URL;
//const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;

function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // New Products
  const getNewProducts = () => {
    const q = query(
      collection(db, REACT_APP_PRODUCT_SPA_URL),
      where('status', '==', 's'),
      orderBy('datetime_submitted', 'desc'),
    );
    onSnapshot(q, (querySnapshot) => {
      const productOBJ = [];
      querySnapshot.forEach((doc) => {
        productOBJ.push({ ...doc.data(), id: doc.id });
      });
      setProducts(productOBJ);
      setLoading(false);
      console.log('Current productOBJ: ', productOBJ);
    });
  };

  // Confirm Orders
  // const getConOrders = () => {
  //   const q = query(
  //     collection(db, SPA_FIREBASE_URL),
  //     where('status', '==', 'a'),
  //     orderBy('datetime_approved', 'desc'),
  //   );
  //   onSnapshot(q, (querySnapshot) => {
  //     const orderOBJ = [];
  //     querySnapshot.forEach((doc) => {
  //       orderOBJ.push({ ...doc.data(), id: doc.id });
  //     });
  //     setConOrders(orderOBJ);
  //     console.log('Current orderOBJ: ', orderOBJ);
  //   });
  // };
  useEffect(() => {
    setLoading(true);
    setTimeout(getNewProducts, 2000);
    // getNewProducts();
    // getConOrders();
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className="mt-3">
            <Col>
              <h6 className="heading heading-col">New Product Orders</h6>
            </Col>
            {/* <Col>
          <h6 className="heading heading-col">Confirmed Orders</h6>
        </Col> */}
          </Row>
          <Row style={{ height: 'auto' }} className="column mt-2">
            {products.length === 0 ? (
              <Col>
                <Alert>No New Products Order Found</Alert>
              </Col>
            ) : (
              ''
            )}
            {products.map((product, index) => {
              return <Product product={product} key={index} />;
            })}

            {/*<Col className="mt-2 column">
           {conOrders.length == '' ? (
            <Alert>No Confirmed Orders Found</Alert>
          ) : (
            ''
          )}
          {conOrders.map((order, index) => {
            return <ConfirmOrder order={order} key={order.id} />;
          })} 
        </Col>*/}
          </Row>
        </>
      )}
    </>
  );
}

export default Products;
