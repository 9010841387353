import React, { useRef } from 'react';
import ReceiptPro from '../ReceiptPro';
import { useReactToPrint } from 'react-to-print';
import { Modal, Button } from 'react-bootstrap';

function ViewProReceipt({ product, showProReceipt, handleCloseProReceipt }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Modal
      show={showProReceipt}
      size="lg"
      onHide={handleCloseProReceipt}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="modal-header-dark">
        <Modal.Title className="modal-header-title">View Receipt</Modal.Title>
      </Modal.Header>
      <ReceiptPro ref={componentRef} product={product} />
      <Modal.Footer>
        <Button variant="light" onClick={handleCloseProReceipt}>
          Close
        </Button>
        <Button variant="light" onClick={handlePrint}>
          Print
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ViewProReceipt;
