import {
  doc,
  serverTimestamp,
  collection,
  increment,
  setDoc,
} from 'firebase/firestore';
import db from '../context/firestore';
const FNBORDERS_NOTI_URL = process.env.REACT_APP_FNBORDERS_NOTI_URL;
export const SPA_FIREBASE_FCM = async (id, ecode, notiType) => {
  var body = null;
  var title = null;
  if (notiType === 'reject') {
    title = 'Appointment Rejected';
    body = 'Your appointment no. ' + id + ' has been cancelled.';
  }
  if (notiType === 'deliver') {
    title = 'Appointment Confirmed';
    body = 'Your appointment no. ' + id + ' has been confirmed.';
  }
  const refCollection = doc(
    collection(db, FNBORDERS_NOTI_URL + ecode + '/DATA/'),
  );
  const countCollection = doc(db, FNBORDERS_NOTI_URL + ecode);
  await setDoc(refCollection, {
    title,
    body,
    type: 2,
    is_seen: false,
    datetime: serverTimestamp(),
  }).then(() => {
    setDoc(
      countCollection,
      {
        notificationCount: increment(1),
      },
      { merge: true },
    );
  });
  // const batch = writeBatch(db);
  // batch.set(refCollection, {
  //   title,
  //   body,
  //   type: 1,
  //   is_seen: false,
  //   datetime: serverTimestamp(),
  // });
  // batch.set(countCollection, { notificationCount: increment(1) });
  // await batch.commit();
};
