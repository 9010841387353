import React from 'react';
import { Row, Col, Alert, Table } from 'react-bootstrap';

function RawInfo({ order }) {
  return (
    <>
      <Col>
        {order.description ? (
          <Alert variant='info'>
            <span className='font-weight-bold'>Description:</span>{' '}
            {order.description}
          </Alert>
        ) : (
          ''
        )}
      </Col>
      <Row className='mt-3'>
        <Col sm={3}>
          <b>Name:</b>
        </Col>
        <Col sm={9} className='text-capitalize'>
          {order.name ? order.name : 'N/A'}
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm={3}>
          <b>Order ID:</b>
        </Col>
        <Col sm={3}>{order.id}</Col>
        <Col sm={3}>
          <b>eCode:</b>
        </Col>
        <Col sm={3}>{order.ecode}</Col>
      </Row>

      <Row className='mt-3'>
        <Col sm={3}>
          <b>Type:</b>
        </Col>
        <Col sm={3} className='text-capitalize'>
          {order.type ? order.type : 'N/A'}
        </Col>
        <Col sm={3}>
          <b>Address:</b>
        </Col>
        <Col sm={3} className='text-capitalize'>
          {/* {order?.address
            ? order.address
            : order?.room_type
            ? order.room_type
            : 'N/A'} */}
          {order?.address ? (
            <span>{order.address}</span>
          ) : order?.room_type ? (
            <span>
              {order.room_type.toLocaleString() === 'couple room'
                ? 'Couple (Husband & Wife)'
                : order.room_type}
            </span>
          ) : (
            'N/A'
          )}
        </Col>
      </Row>
      <Row className='mt-3'>
        {order.room_type === 'couple room' ? (
          <>
            <Col sm={3}>
              <b>Couple Ecode:</b>
            </Col>
            <Col sm={3} className='text-capitalize'>
              {order.couple_ecode ? order.couple_ecode : 'N/A'}
            </Col>
          </>
        ) : (
          ''
        )}
        <Col sm={3}>
          <b>Payment By:</b>
        </Col>
        <Col sm={3} className='text-capitalize'>
          {order.payment_type}
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm={3}>
          <b>Booking:</b>
        </Col>
        <Col sm={3} className='text-capitalize'>
          {order.booking_date}
        </Col>
      </Row>
      <Row className='mt-3'>
        <h5>Service:</h5>
        <Col className='px-3'>
          <Table striped bordered hover variant='dark'>
            <thead>
              <tr>
                <th>Type</th>
                <th>Category</th>
                <th>Name</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{order.service.type}</td>
                <td>{order.service.catname}</td>
                <td>{order.service.name}</td>
                <td>{order.service.price.toFixed(2)} SAR</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm={3}>
          <b>Additional Price:</b>
        </Col>
        <Col sm={1} className='text-capitalize'>
          {order.service.additionalrate}
        </Col>
        <Col sm={3}>
          <b>Slots Required:</b>
        </Col>
        <Col className='text-capitalize' sm={1}>
          {order.service.slotrequired}
        </Col>
        <Col sm={3}>
          <b>Duration:</b>
        </Col>
        <Col className='text-capitalize' sm={1}>
          {order.service.duration}
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col sm={5}>
          <b>Additional Time:</b>
        </Col>
        <Col sm={1} className='text-capitalize'>
          {order.additional_time}
        </Col>
        <Col sm={5}>
          <b>Additional Rate:</b>
        </Col>
        <Col className='text-capitalize' sm={1}>
          {order.addtional_rate}
        </Col>
      </Row>
    </>
  );
}

export default RawInfo;
