import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { deviceId } from '../../context/firestore';
import { Row, Col, Form, Badge } from 'react-bootstrap';
import Loading from '../Loading/Loading';
import NotFound from '../HTML/NotFound';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
const moment = require('moment');

//const ENV = process.env.REACT_APP_ENV;
const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;

function Schedule() {
  const { auth } = useAuth();
  const token = auth.access_token;
  let date = moment(new Date()).format('YYYY-MM-DD');
  const navigate = useNavigate();
  const [staff, setStaff] = useState([]);
  const [data, setData] = useState([]);
  const [scheduleDate, setScheduleDate] = useState(date);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [loading, setLoading] = useState(false);

  const getStafFromDb = async () => {
    await setStaff(JSON.parse(localStorage.getItem('staff')));
    setLoading(false);
  };

  const getStaffSlots = async (e) => {
    setLoading(true);
    var empCode = e.target.value;
    const empName = e.target[e.target.selectedIndex].getAttribute('data-name');
    setSelectedStaff({ empCode, empName });

    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        deviceid: deviceId,
      },
      params: {
        empCode,
        date: scheduleDate,
      },
    };

    var URL = SPA_SERVER_URL + 'api/spa/getstaffslotweekly';
    await axios
      .get(URL, config)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.dates);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 400) {
          navigate('/login');
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(getStafFromDb, 2000);
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className='mt-3'>
            <Col>
              <h6 className='heading heading-col 33'>Employees Timetable</h6>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col sm={{ span: 3, offset: 3 }}>
              <Form.Control
                aria-label='Start Date'
                type='date'
                onChange={(e) => {
                  setScheduleDate(e.target.value);
                }}
                min={date}
                name='startdate'
                value={scheduleDate}
              />
            </Col>
            <Col md={{ span: 3, offset: 0 }}>
              {staff.length > 0 ? (
                <Form.Select
                  className='me-sm-2'
                  readOnly
                  onChange={(e) => getStaffSlots(e)}
                >
                  <option value=''>Select Employee</option>
                  {staff.map((st, index) => {
                    return (
                      <option
                        value={st.EmpCode}
                        data-name={st.EmpName}
                        key={index}
                      >
                        {st.EmpName}
                      </option>
                    );
                  })}
                </Form.Select>
              ) : (
                <NotFound />
              )}
            </Col>
          </Row>
          <Row style={{ height: 'auto' }} className=' mt-5'>
            <Col md={{ span: 6, offset: 3 }} style={{ textAlign: 'center' }}>
              <h4>
                {selectedStaff.empName} - {selectedStaff.empCode}
              </h4>
            </Col>
          </Row>
          <Row style={{ height: 'auto' }} className='column mt-5'>
            {data.length > 0
              ? data.map((d, index) => {
                  return (
                    <Col key={index}>
                      <Row>
                        <Col className='timetable-header'>
                          {d.day} <br></br>
                          {d.date}
                        </Col>
                      </Row>
                      {d.timeSlots.length > 0 ? (
                        d.timeSlots.map((slot, i) => {
                          return (
                            <Row key={i} className='mt-3'>
                              <Col className='time-col'>
                                <Badge
                                  pill
                                  bg='dark'
                                  className='m-1 p-2'
                                  key={i}
                                >
                                  {slot.value}
                                </Badge>
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <Row className='mt-3'>
                          <Col style={{ textAlign: 'center' }}>N/A</Col>
                        </Row>
                      )}
                    </Col>
                  );
                })
              : ''}
          </Row>
        </>
      )}
    </>
  );
}

export default Schedule;
