import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Sidebar extends Component {
  render() {
    return (
      <div className='row justify-content-center mt-5'>
        <div className='col col-5 menu1 p-0'>
          <ul className='nav my-1'>
            <li className='nav-item'>
              <NavLink className='nav-link active' to='/orders'>
                Orders
              </NavLink>
              {/* <span className="text-center">Orders</span> */}
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/products'>
                Products
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/schedule'>
                Schedule
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/history/spa'>
                History
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/logout'>
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
