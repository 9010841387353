import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import logo from '../../../assets/images/nakls.png';
import moment from 'moment';

const Receipt = React.forwardRef(({ order }, ref) => {
  const convertDate = (date) => {
    if (date.seconds == null) {
      return moment(date).format('YYYY-MM-DD h:mm');
    }
    if (date != null) {
      return new Date(date.seconds * 1000).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else {
      return 'N/A';
    }
  };
  return (
    <Modal.Body className="mx-3" ref={ref}>
      <Row className="mt-3">
        <Col className="col-sm-12 d-flex justify-content-center">
          <img src={logo} alt="" width="200" className="img-fluid" />
        </Col>
      </Row>
      <Row className="mt-3 justify-content-center">
        <Col className="col-sm-12 col-md-4">
          <p className="vat text-center">
            <span>VAT NO: </span>310168166700003
          </p>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>Bill Id</Col>
        <Col className="text-end">{order.id}</Col>
      </Row>
      <Row className="mt-2">
        <Col>eCode</Col>
        <Col className="text-end">{order.ecode}</Col>
      </Row>
      <Row className="mt-2">
        <Col>Address</Col>
        <Col className="text-end text-capitalize">
          {/* {order?.address
            ? order.address
            : order?.room_type
            ? order.room_type
            : 'N/A'} */}
          {order?.address ? (
            <span>{order.address}</span>
          ) : order?.room_type ? (
            <span>
              {order.room_type.toLocaleString() === 'couple room'
                ? 'Couple (Husband & Wife)'
                : order.room_type}
            </span>
          ) : (
            'N/A'
          )}
        </Col>
      </Row>
      {order.room_type?.toLowerCase() === 'couple room' ? (
        <Row className="mt-2">
          <Col>Couple Ecode</Col>
          <Col className="text-end text-capitalize">
            {order.couple_ecode ? order.couple_ecode : 'N/A'}
          </Col>
        </Row>
      ) : (
        ''
      )}
      <Row className="mt-2 ">
        <Col>Booking</Col>
        <Col className="text-end text-capitalize date-class">
          {order.booking_date}
        </Col>
      </Row>
      <Row className="mt-2 ">
        <Col>Order Date</Col>
        <Col className="text-end text-capitalize date-class">
          {convertDate(order.datetime_submitted)}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-3">
          {/* striped bordered hover variant="dark" */}
          <Table>
            <thead className="table-dark">
              <tr>
                <th>Type</th>
                <th>Category</th>
                <th>Name</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{order.service?.type}</td>
                <td>{order.service.catname}</td>
                <td>{order.service.name}</td>
                <td>{order.service.price.toFixed(2)} SAR</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="px-3">
          <Table className="receipt-amount-table">
            <thead className="table-dark">
              <tr>
                <th>Subtotal</th>
                <th className="text-end">{order.subtotal.toFixed(2)} SAR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>VAT 15%</td>
                <td className="text-end">{order.vat.toFixed(2)} SAR</td>
              </tr>
              <tr>
                <td>Tip Amount</td>
                <td className="text-end">
                  {order.tip ? order.tip.toFixed(2) : 0} SAR
                </td>
              </tr>
              <tr>
                <td>Discount</td>
                <td className="text-end">
                  {order.discount ? order.discount.toFixed(2) : 0} SAR
                  {order.discount_percentage
                    ? ' - (' + order.discount_percentage + ' %)'
                    : ''}
                </td>
              </tr>
              <tr>
                <td>Net Amount</td>
                <td className="text-end">{order.total.toFixed(2)} SAR</td>
              </tr>
              <tr>
                <td>Payment By</td>
                <td className="text-end">{order.payment_type}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Modal.Body>
  );
});

export default Receipt;
