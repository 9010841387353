import React, { useEffect, useState } from 'react';
import ConfirmOrder from './confirmOrder';
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import moment from 'moment';
import db from '../../context/firestore';
import { Row, Col, Alert, InputGroup, Form, Button } from 'react-bootstrap';
import Order from './Order';
import Loading from '../Loading/Loading';

const SPA_FIREBASE_URL = process.env.REACT_APP_SPA_FIREBASE_URL;
const Orders = () => {
  const [newOrders, setNewOrders] = useState([]);
  const [conOrders, setConOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  let date = moment(new Date()).format('YYYY-MM-DD');
  const [tillDate, setTillDate] = useState('');
  // New Orders
  const getNewOrders = (tillDate) => {
    var q = query(
      collection(db, SPA_FIREBASE_URL),
      where('status', '==', 's'),
      orderBy('datetime_submitted', 'desc')
    );
    if (tillDate !== '') {
      q = query(
        collection(db, SPA_FIREBASE_URL),
        where('status', '==', 's'),
        where('booking_date', '==', tillDate),
        orderBy('datetime_submitted', 'desc')
      );
    }

    onSnapshot(q, (querySnapshot) => {
      const orderOBJ = [];
      querySnapshot.forEach((doc) => {
        orderOBJ.push({ ...doc.data(), id: doc.id });
      });
      setNewOrders(orderOBJ);
      console.log('Current orderOBJ: ', orderOBJ);
    });
  };

  // Confirm Orders
  const getConOrders = (tillDate) => {
    var q = query(
      collection(db, SPA_FIREBASE_URL),
      where('status', '==', 'a'),
      orderBy('datetime_approved', 'desc')
    );
    if (tillDate !== '') {
      q = query(
        collection(db, SPA_FIREBASE_URL),
        where('status', '==', 'a'),
        where('booking_date', '==', tillDate),
        orderBy('datetime_approved', 'desc')
      );
    }
    onSnapshot(q, (querySnapshot) => {
      const orderOBJ = [];
      querySnapshot.forEach((doc) => {
        orderOBJ.push({ ...doc.data(), id: doc.id });
      });
      setConOrders(orderOBJ);
      console.log('Current orderOBJ: ', orderOBJ);
    });
  };

  const getNextDate = () => {
    setLoading(true);
    getNewOrders(tillDate);
    getConOrders(tillDate);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const resetDate = () => {
    setTillDate('');
    setLoading(true);
    getNewOrders('');
    getConOrders('');
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setLoading(true);
    getNewOrders(tillDate);
    getConOrders(tillDate);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [tillDate]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className='mt-3'>
            <Col>
              <h6 className='heading heading-col'>Orders Schedule</h6>
            </Col>
            <Col className='mt-5' sm={12}>
              <Col sm={{ span: 8, offset: 2 }}>
                <InputGroup className='mb-3'>
                  <Form.Control
                    aria-label='Start Date'
                    type='date'
                    onChange={(e) => {
                      setTillDate(e.target.value);
                    }}
                    min={date}
                    name='tilldate'
                    value={tillDate}
                  />

                  <Button
                    className='mx-3'
                    variant='outline-dark'
                    id='getHistory'
                    onClick={getNextDate}
                  >
                    Check Schedule
                  </Button>
                  <Button
                    variant='outline-dark'
                    id='getHistory'
                    onClick={resetDate}
                  >
                    All Orders
                  </Button>
                </InputGroup>
              </Col>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <h6 className='heading heading-col'>New Orders</h6>
            </Col>
            <Col>
              <h6 className='heading heading-col'>Confirmed Orders</h6>
            </Col>
          </Row>
          <Row style={{ height: '100vh' }}>
            <Col className='mt-2 column'>
              {newOrders.length === 0 ? <Alert>No New Orders Found</Alert> : ''}
              {newOrders.map((order, index) => {
                return <Order order={order} key={order.id} />;
              })}
            </Col>

            <Col className='mt-2 column'>
              {conOrders.length === 0 ? (
                <Alert>No Confirmed Orders Found</Alert>
              ) : (
                ''
              )}
              {conOrders.map((order, index) => {
                return <ConfirmOrder order={order} key={order.id} />;
              })}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Orders;
