import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { doc, updateDoc } from 'firebase/firestore';

import db from '../../../context/firestore';
import Loading from '../../Loading/Loading';

const SPA_FIREBASE_URL = process.env.REACT_APP_SPA_FIREBASE_URL;
function RoomComponent({ id, type }) {
  const [room, setRoom] = useState('');
  const [loading, setLoading] = useState(false);
  const [showName, setShowName] = useState('');

  async function updateRoom(id) {
    setLoading(true);
    if (room !== '') {
      const docRef = doc(db, SPA_FIREBASE_URL + id.toString());
      if (type === 'couple') {
        await updateDoc(docRef, {
          assigned_couple_room: room,
        });
      } else {
        await updateDoc(docRef, {
          assigned_room: room,
        });
      }
      setShowName(room);
      setLoading(false);
      toast.success('Room Updated for order ID ' + id);
    } else {
      setShowName('');
      setLoading(false);
      toast.error('Empty Room Cant be Selected');
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Col>
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-1" controlId="formBasicEmail">
                <Form.Label>
                  <b>
                    {type === 'couple'
                      ? 'Select Room for Couple'
                      : 'Select Room'}
                  </b>
                </Form.Label>
                <Form.Select
                  className="me-sm-2"
                  onChange={(e) => {
                    setRoom(e.target.value);
                  }}
                >
                  <option></option>
                  <option value="Room 1">Room 1</option>
                  <option value="Room 2">Room 2</option>
                  <option value="Room 3">Room 3</option>
                  <option value="Room 4">Room 4</option>
                  <option value="Room 5">Room 5</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={12} className="mt-1 d-grid gap-2">
              <Button
                className="btn btn-sm btn-dark mt-2 py-2"
                onClick={() => updateRoom(id)}
              >
                Update
              </Button>
              <span>
                {showName ? (
                  <div className="selectedname">{showName} | Selected</div>
                ) : (
                  ''
                )}
              </span>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}

export default RoomComponent;
